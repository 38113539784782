export function alternarOffcanvas(offcanvas) {

    if (offcanvas === null) {
        return;
    }

    window.onpopstate = (event) => {
        if (event != null)
            bootstrap.Offcanvas.getOrCreateInstance(offcanvas).hide();
    };

    offcanvas.addEventListener('shown.bs.offcanvas', () => history.pushState({}, ''));
}
